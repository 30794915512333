import { Grid, Typography } from "@mui/material";
import React from "react";
import ParagraphText from "../ParagraphText";
import TitleH4 from "../TitleH4";

const Profile = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}>
        {/* Left column (empty) */}
      </Grid>
      <Grid item xs={12} md={8}>
        <ParagraphText>
          Triptic was founded in 2013 by two architects, Aurel Basuc and Adrian
          Tutuianu, along with business developer Cristian Danciu. We set out on
          a mission to revolutionize the design and construction industry by
          infusing it with creativity and innovation. With unwavering
          dedication, we apply our collective expertise, interdisciplinary
          perspectives, and local knowledge to tackle complex challenges in a
          new and fresh manner.
        </ParagraphText>
        <ParagraphText>
          Whether engaged at any phase of a project or involved throughout its
          entirety, we take pride in our role as catalysts of creative vision
          and technical excellence. Our aim is to surpass expectations by
          delivering exceptional resources and services to our clients. We are
          committed to enhancing the places where people live and work, creating
          environments that nurture and inspire. Through our work, we strive to
          contribute to a sustainable world, one in which all individuals will
          flourish.
        </ParagraphText>
        <TitleH4>ABOUT US</TitleH4>
        <ParagraphText>
          Triptic is a forward-thinking architectural design firm dedicated to
          reimagining spaces and inspiring possibilities with a strong emphasis
          on environmental responsibility. With our team of passionate and
          experienced professionals, we specialize in creating unique,
          sustainable, and transformative designs that exceed expectations. Our
          unwavering commitment to precision, meticulous attention to detail,
          and collaborative ethos has positioned us as a trusted leader in the
          architectural industry in Romania.
        </ParagraphText>
        <TitleH4>OUR VISION</TitleH4>
        <ParagraphText>
          We envision crafting architectural projects that seamlessly merge
          aesthetics, functionality, and sustainability, transforming spaces
          into comfortable experiences that leave a lasting impact on both
          occupants and the environment. With a deep-rooted commitment to
          environmental stewardship, we prioritize green solutions and strive to
          minimize the carbon footprint of all our projects.
        </ParagraphText>
        <TitleH4>OUR MISSION</TitleH4>
        <ParagraphText>
          Our mission is to deliver unparalleled architectural design services
          that harmoniously integrate aesthetics, functionality, and
          sustainability. We go beyond conventional boundaries, pushing creative
          limits, and embracing innovative approaches to ensure our designs are
          not only visually stunning but also environmentally conscious. By
          placing our clients’ dreams at the forefront of every design
          endeavour, we create spaces that not only fulfil their aspirations but
          also contribute to a greener and more sustainable future.
        </ParagraphText>
      </Grid>
      <Grid item xs={12} md={2}>
        {/* Right column (empty) */}
      </Grid>
    </Grid>
  );
};

export default Profile;
