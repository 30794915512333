const images = {
  CPM_CT_retail_2016_01: [
    require("../assets/projects/CPM_CT_retail_2016_01/1.jpg"),
    require("../assets/projects/CPM_CT_retail_2016_01/2.jpg"),
    require("../assets/projects/CPM_CT_retail_2016_01/3.jpg"),
    require("../assets/projects/CPM_CT_retail_2016_01/4.jpg"),
    require("../assets/projects/CPM_CT_retail_2016_01/5.jpg"),
  ],
  Avangarde_rezi_2017_01: [
    require("../assets/projects/Avangarde_rezi_2017_01/1.jpg"),
    require("../assets/projects/Avangarde_rezi_2017_01/2.jpg"),
    require("../assets/projects/Avangarde_rezi_2017_01/3.jpg"),
    require("../assets/projects/Avangarde_rezi_2017_01/4.jpg"),
  ],
  WY_industrial_2017_02: [
    require("../assets/projects/WY_industrial_2017_02/1.jpg"),
    require("../assets/projects/WY_industrial_2017_02/2.jpg"),
    require("../assets/projects/WY_industrial_2017_02/3.jpg"),
    require("../assets/projects/WY_industrial_2017_02/4.jpg"),
    require("../assets/projects/WY_industrial_2017_02/5.jpg"),
  ],
  SHB_rezi_2018_01: [
    require("../assets/projects/SHB_rezi_2018_01/1.jpg"),
    require("../assets/projects/SHB_rezi_2018_01/2.jpg"),
    require("../assets/projects/SHB_rezi_2018_01/3.jpg"),
  ],
  ABB_office_2019_01: [
    require("../assets/projects/ABB_office_2019_01/01.jpg"),
    require("../assets/projects/ABB_office_2019_01/02.jpg"),
    require("../assets/projects/ABB_office_2019_01/03.jpg"),
    require("../assets/projects/ABB_office_2019_01/04.jpg"),
  ],
  Penny_retail_2019_02: [
    require("../assets/projects/Penny_retail_2019_02/1.jpg"),
    require("../assets/projects/Penny_retail_2019_02/2.jpg"),
    require("../assets/projects/Penny_retail_2019_02/3.jpg"),
  ],
  Elemaster_industrial_2019_03: [
    require("../assets/projects/Elemaster_industrial_2019_03/1.jpg"),
    require("../assets/projects/Elemaster_industrial_2019_03/2.jpg"),
    require("../assets/projects/Elemaster_industrial_2019_03/3.jpg"),
    require("../assets/projects/Elemaster_industrial_2019_03/4.jpg"),
  ],
  Mamaia_public_2020_01: [
    require("../assets/projects/Mamaia_public_2020_01/1.jpg"),
    require("../assets/projects/Mamaia_public_2020_01/2.jpg"),
    require("../assets/projects/Mamaia_public_2020_01/3.jpg"),
  ],
  Peninsula_public_2020_02: [
    require("../assets/projects/Peninsula_public_2020_02/1.jpg"),
    require("../assets/projects/Peninsula_public_2020_02/2.jpg"),
    require("../assets/projects/Peninsula_public_2020_02/3.jpg"),
    require("../assets/projects/Peninsula_public_2020_02/4.jpg"),
  ],
  Mamaia_public_2020_03: [
    require("../assets/projects/Mamaia_public_2020_03/1.jpg"),
    require("../assets/projects/Mamaia_public_2020_03/2.jpg"),
    require("../assets/projects/Mamaia_public_2020_03/3.jpg"),
  ],
  Bucur_House_rezi_2021_01: [
    require("../assets/projects/Bucur_House_rezi_2021_01/1.jpg"),
    require("../assets/projects/Bucur_House_rezi_2021_01/2.jpg"),
    require("../assets/projects/Bucur_House_rezi_2021_01/3.jpg"),
    require("../assets/projects/Bucur_House_rezi_2021_01/4.jpg"),
  ],
  Maserati_office_2021_02: [
    require("../assets/projects/Maserati_office_2021_02/1.jpg"),
    require("../assets/projects/Maserati_office_2021_02/2.jpg"),
    require("../assets/projects/Maserati_office_2021_02/3.jpg"),
    require("../assets/projects/Maserati_office_2021_02/4.jpg"),
    require("../assets/projects/Maserati_office_2021_02/5.jpg"),
  ],
  Sibiu_acces_public_2021_03: [
    require("../assets/projects/Sibiu_acces_public_2021_03/1.jpg"),
    require("../assets/projects/Sibiu_acces_public_2021_03/2.jpg"),
    require("../assets/projects/Sibiu_acces_public_2021_03/3.jpg"),
    require("../assets/projects/Sibiu_acces_public_2021_03/4.jpg"),
    require("../assets/projects/Sibiu_acces_public_2021_03/5.jpg"),
  ],
  Sibiu_Remiza_public_2021_04: [
    require("../assets/projects/Sibiu_Remiza_public_2021_04/1.jpg"),
    require("../assets/projects/Sibiu_Remiza_public_2021_04/2.jpg"),
    require("../assets/projects/Sibiu_Remiza_public_2021_04/3.jpg"),
    require("../assets/projects/Sibiu_Remiza_public_2021_04/4.jpg"),
  ],
  Curte_scoala_public_2022_01: [
    require("../assets/projects/Curte_scoala_public_2022_01/1.jpg"),
    require("../assets/projects/Curte_scoala_public_2022_01/2.jpg"),
    require("../assets/projects/Curte_scoala_public_2022_01/3.jpg"),
    require("../assets/projects/Curte_scoala_public_2022_01/4.jpg"),
    require("../assets/projects/Curte_scoala_public_2022_01/5.jpg"),
  ],
  Harman_House_rezi_2022_02: [
    require("../assets/projects/Harman_House_rezi_2022_02/1.jpg"),
    require("../assets/projects/Harman_House_rezi_2022_02/2.jpg"),
    require("../assets/projects/Harman_House_rezi_2022_02/3.jpg"),
    require("../assets/projects/Harman_House_rezi_2022_02/4.jpg"),
    require("../assets/projects/Harman_House_rezi_2022_02/5.jpg"),
  ],
  CRP_retail_2022_03: [
    require("../assets/projects/CRP_retail_2022_03/1.jpg"),
    require("../assets/projects/CRP_retail_2022_03/2.jpg"),
    require("../assets/projects/CRP_retail_2022_03/3.jpg"),
    require("../assets/projects/CRP_retail_2022_03/4.jpg"),
    require("../assets/projects/CRP_retail_2022_03/5.jpg"),
  ],
  UPB1_public_2023_01: [
    require("../assets/projects/UPB1_public_2023_01/1.jpg"),
    require("../assets/projects/UPB1_public_2023_01/2.jpg"),
    require("../assets/projects/UPB1_public_2023_01/3.jpg"),
    require("../assets/projects/UPB1_public_2023_01/4.jpg"),
    require("../assets/projects/UPB1_public_2023_01/5.jpg"),
    require("../assets/projects/UPB1_public_2023_01/6.jpg"),
  ],
  Cresa_public_2023_02: [
    require("../assets/projects/Cresa_public_2023_02/1.jpg"),
    require("../assets/projects/Cresa_public_2023_02/2.jpg"),
    require("../assets/projects/Cresa_public_2023_02/3.jpg"),
    require("../assets/projects/Cresa_public_2023_02/4.jpg"),
  ],
  Stadion_Selimbar_public_2023_03: [
    require("../assets/projects/Stadion_Selimbar_public_2023_03/1.jpg"),
    require("../assets/projects/Stadion_Selimbar_public_2023_03/2.jpg"),
    require("../assets/projects/Stadion_Selimbar_public_2023_03/3.jpg"),
    require("../assets/projects/Stadion_Selimbar_public_2023_03/4.jpg"),
    require("../assets/projects/Stadion_Selimbar_public_2023_03/5.jpg"),
    require("../assets/projects/Stadion_Selimbar_public_2023_03/6.jpg"),
    require("../assets/projects/Stadion_Selimbar_public_2023_03/7.jpg"),
  ],
  Stadion_Bv_public_2023_04: [
    require("../assets/projects/Stadion_Bv_public_2023_04/1.jpg"),
    require("../assets/projects/Stadion_Bv_public_2023_04/2.jpg"),
    require("../assets/projects/Stadion_Bv_public_2023_04/3.jpg"),
    require("../assets/projects/Stadion_Bv_public_2023_04/4.jpg"),
    require("../assets/projects/Stadion_Bv_public_2023_04/5.jpg"),
    require("../assets/projects/Stadion_Bv_public_2023_04/6.jpg"),
    require("../assets/projects/Stadion_Bv_public_2023_04/7.jpg"),
  ],
  Medical_Clinic_public_2021_05: [
    require("../assets/projects/Medical_Clinic_public_2021_05/1.jpg"),
    require("../assets/projects/Medical_Clinic_public_2021_05/2.jpg"),
    require("../assets/projects/Medical_Clinic_public_2021_05/3.jpg"),
    require("../assets/projects/Medical_Clinic_public_2021_05/4.jpg"),
    require("../assets/projects/Medical_Clinic_public_2021_05/5.jpg"),
    require("../assets/projects/Medical_Clinic_public_2021_05/6.jpg"),
 
  ],
  Kindergarten_public_2024_01: [
    require("../assets/projects/Kindergarten_public_2024_01/1.jpg"),
    require("../assets/projects/Kindergarten_public_2024_01/2.jpg"),
    require("../assets/projects/Kindergarten_public_2024_01/3.jpg"),
    require("../assets/projects/Kindergarten_public_2024_01/4.jpg"),
    require("../assets/projects/Kindergarten_public_2024_01/5.jpg"),

  ],
  Oceanis_private_2024_02: [
    require("../assets/projects/Oceanis_private_2024_02/1.jpg"),
    require("../assets/projects/Oceanis_private_2024_02/2.jpg"),
    require("../assets/projects/Oceanis_private_2024_02/3.jpg"),
    require("../assets/projects/Oceanis_private_2024_02/4.jpg"),
    require("../assets/projects/Oceanis_private_2024_02/5.jpg"),

  ],
  Madison_rezi_2024_03: [
    require("../assets/projects/Madison_rezi_2024_03/1.jpg"),
    require("../assets/projects/Madison_rezi_2024_03/2.jpg"),
    require("../assets/projects/Madison_rezi_2024_03/3.jpg"),

  ]


};

export default images;
