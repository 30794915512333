import React from "react";
import Banner from "../components/banner";
import banners from "../data/banners";
import TeamMember from "../components/teamMember";
import team from "../data/team";
import {
  Grid,

  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ClientsList from "../components/office/ClientsList";
import GuidesList from "../components/office/GuidesList";
import Profile from "../components/office/Profile";
import Services from "../components/office/Services";
import Jobs from "../components/office/Jobs";
import Awards from "../components/office/Awards";

const Office = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box id={banners.profile.title} sx={{ my: 4 }}>
          <Banner
            imageUrl={banners.profile.image}
            text={banners.profile.title}
          />
        </Box>
        <Profile />
      </Grid>

      <Grid item xs={12}>
        <Box id={banners.services.title} sx={{ my: 4 }}>
          <Banner
            imageUrl={banners.services.image}
            text={banners.services.title}
          />
        </Box>
        <Box>
          <Services />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box id={banners.employment.title} sx={{ my: 4 }}>
          <Banner
            imageUrl={banners.employment.image}
            text={banners.employment.title}
          />
        </Box>
        <Box>
          <Jobs />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box id={banners.people.title} sx={{ my: 4 }}>
          <Banner imageUrl={banners.people.image} text={banners.people.title} />
        </Box>
      </Grid>
      {/* Team Members Grid */}
      {team.map((member) => (
        <Grid item xs={12} sm={6} md={4} key={member._id}>
          <TeamMember
            name={member.name}
            job={member.job}
            image={member.image}
            email={member.email}
            telefon={member.telefon}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Box id={banners.publications.title} sx={{ my: 4 }}>
          <Banner
            imageUrl={banners.publications.image}
            text={banners.publications.title}
          />
        </Box>
        <Box sx={{ my: 4 }}>
          <GuidesList />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box id={banners.awards.title} sx={{ my: 4 }}>
          <Banner imageUrl={banners.awards.image} text={banners.awards.title} />
        </Box>
        <Box>
          <Awards />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box id={banners.clients.title} sx={{ my: 4 }}>
          <Banner
            imageUrl={banners.clients.image}
            text={banners.clients.title}
          />
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ClientsList />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Office;
