// GuideCard.tsx
import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ParagraphText from "../ParagraphText";
import TitleH4 from "../TitleH4";

interface GuideCardProps {
  name: string;
  imageUrl: string;
  title: string;
  description: string;
  guideUrl: string;
  index: number; // Accept index as a prop
}

const GuideCard: React.FC<GuideCardProps> = ({
  name,
  imageUrl,
  title,
  description,
  guideUrl,
  index,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isImageFirst = isMobile || index % 2 === 0;

  const downloadFile = async (url:string) => {
    try {
      const response = await fetch(url, { mode: 'no-cors' });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = url.split("/").pop() || "downloaded_file.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} order={isImageFirst ? 1 : 2}>
        <Box
          component="img"
          src={imageUrl}
          alt={name}
          sx={{ width: "100%", height: "auto" }}
        />
      </Grid>
      <Grid item xs={12} md={6} order={isImageFirst ? 2 : 1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            textAlign: "center",
            p: 4,
          }}
        >
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                sx={{
                  lineHeight: "1.8",
                  color: "#434343",
                  fontWeight: 900,
                  py: 2,
                }}
              >
              {title}
              </Typography>
         
          <ParagraphText>{description}</ParagraphText>

          <a href={guideUrl} download
        onClick={(e) => {
          e.preventDefault();
          downloadFile(guideUrl);
        }}
          
          style={{ textDecoration: "none" }} target="_blank">
            <Button
              variant="outlined"
              size="large"
              sx={{
                color: "black",
                borderColor: "black",
                borderWidth: "2px",
                borderRadius: 0,
                fontWeight: "bold",
                "&:hover": {
                  borderColor: "black",
                  backgroundColor: "transparent",
                },
              }}
            >
              Download guide
            </Button>
          </a>
        </Box>
      </Grid>
    </Grid>
  );
};

export default GuideCard;
