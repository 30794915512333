import React from "react";
import ParagraphText from "../ParagraphText";
import TitleH4 from "../TitleH4";
import {
  Grid,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";

const Jobs = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <TitleH4>JOBS</TitleH4>
        <ParagraphText>
          All applications (solicited and unsolicited) should be submitted
          through our application system below.
        </ParagraphText>
        <ParagraphText>
          We review all applications on a bi-weekly basis and store relevant
          applications for a maximum period of 6 months after which they are
          deleted.
        </ParagraphText>

        <ParagraphText>
          Submitted files should be in .pdf format only and not exceed a size of
          10 Mb.
        </ParagraphText>

        <Button
          variant="outlined"
          size="large"
          component={Link}
          to={`/working-at-triptic`}
          sx={{
            color: "black",
            borderColor: "black",
            borderWidth: "2px",
            borderRadius: 0,
            fontWeight: "bold",
            "&:hover": {
              borderColor: "black",
              borderWidth: "2px",
              backgroundColor: "transparent",
            },
          }}
        >
          working at TRIPTIC
        </Button>
      </Grid>
      <Grid item xs={12} md={6} sx={{ p: 2 }}>
        <TitleH4>INTERNSHIP</TitleH4>
        <ParagraphText>
          We are always looking for talented students of architecture, landscape
          architecture, urban planning, and constructing architects to join our
          team.
        </ParagraphText>
        <ParagraphText>
          We accept 6 - 8 interns during the spring and the fall semester, which
          run from August through January and from February through June.
        </ParagraphText>
        <ParagraphText>
          We do not offer summer internships and only students enrolled at an
          accredited university are eligible to apply for an internship.
        </ParagraphText>
        <ParagraphText>
          The Architecture Internship positions for the Fall 2024 semester have
          been filled. This excludes the Innovation Intern and the Business
          Development Intern position which are still active.
        </ParagraphText>

        <Button
          variant="outlined"
          size="large"
          sx={{
            color: "black",
            borderColor: "black",
            borderWidth: "2px", // Increase border width
            borderRadius: 0, // Set border-radius to 0 for no border-radius
            fontWeight: "bold", // Make the text bolder
            "&:hover": {
              borderColor: "black", // Ensure the border color remains black on hover
              borderWidth: "2px", // Maintain the border width on hover
              backgroundColor: "transparent", // Optional: Set a transparent background on hover
            },
          }}
        >
          APPLY NOW
        </Button>
      </Grid>
    </Grid>
  );
};

export default Jobs;
