const guides = [
  {
    name: "URBAN REGENERATION GUIDE FOR MAMAIA RESORT",
    imageUrl: require("../assets/files/01_mamaia.jpg"),
    title: "URBAN REGENERATION GUIDE FOR MAMAIA RESORT",
    description:
      "The Urban Regeneration Guide is designed to enhance the quality of life for residents and visitors alike, while promoting sustainable development and environmental stewardship. It outlines a series of proposed initiatives focused on improving green spaces, pedestrian and vehicular circulation, parking facilities, relaxation areas, plazas, promenades, and smart urban furniture. The Guide is a forward-thinking initiative that aims to create a sustainable, vibrant, and inclusive environment for everyone. We invite community members, stakeholders, and visitors to engage with this guide and contribute their ideas as we work together to bring this vision to life.",
    guideUrl: "https://triptic.ro/files/01_ARH_ICS_GHID%202018_WORLD%20BANK_MAMAIA.pdf",
  },
  {
    name: "URBAN DESIGN GUIDE FOR THE ESPLANADE IN CONSTANȚA",
    imageUrl: require("../assets/files/02_constanta.jpg"),
    title: "URBAN DESIGN GUIDE FOR THE ESPLANADE IN CONSTANȚA",
    description:
      "One of the primary objectives of the Urban Design Guide is to integrate more green spaces into the promenade area. These spaces will not only beautify the surroundings but also provide essential ecological benefits, such as improving air quality and supporting local biodiversity. The guide emphasizes the importance of planting native flora and creating community gardens, which will serve as gathering spots for social interaction and relaxation. The guide places a strong emphasis on pedestrian circulation, aiming to create a safe and enjoyable experience for walkers. This includes the design of wide, accessible pathways that encourage leisurely strolls along the promenade. To complement the natural beauty of the promenade, the Urban Design Guide includes recommendations for urban furniture that enhances both comfort and aesthetics. This includes benches, tables, and shade structures that are not only functional but also reflect the local culture and history. ",
    guideUrl: "https://triptic.ro/files/02_ARH_CT_WBG_DETALIERE_PROMENADA%20CONSTANTA_PREZENTARE.pdf",
  },
  {
    name: "THE GUIDE FOR SCHOOL COURTYARDS DESIGN ",
    imageUrl: require("../assets/files/03_curtea_scolii.jpg"),
    title: "THE GUIDE FOR SCHOOL COURTYARDS DESIGN ",
    description:
      "The School Courtyard Design Guide is a comprehensive resource aimed at transforming school outdoor spaces into vibrant, functional, and engaging environments for students and staff. This guide provides detailed recommendations for the design and equipping of school courtyards, focusing on creating areas that promote learning, play, and social interaction. We invite educators, administrators, and community members to explore this guide and join us in our mission to create inspiring outdoor spaces that support the well-being and development of our students. Together, we can make our school courtyards a place where learning and play go hand in hand!",
    guideUrl: "https://triptic.ro/files/03_ARH_ICS_GHID%202020_WORLD%20BANK_CURTEA%20SCOLII.pdf",
  },
  {
    name: "#153 SCHOOL COURTYARD DESIGN GUIDE",
    imageUrl: require("../assets/files/04_scoala_153.jpg"),
    title: "#153 SCHOOL COURTYARD DESIGN GUIDE",
    description:
      "The guide covers various aspects for courtyard #153 School Courtyard design, from Bucharest, including landscaping, seating arrangements, play areas, and educational zones. By incorporating elements that encourage creativity and collaboration, we aim to enhance the overall educational experience and foster a sense of community within the school. We invite educators, administrators, and community members to explore this guide and join us in our mission to create inspiring outdoor spaces that support the well-being and development of our students. Together, we can make our school courtyards a place where learning and play go hand in hand! ",
    guideUrl: "https://triptic.ro/files/04_ARH_S153_S6_GHID%202021_WORLD%20BANK.pdf",
  },
  {
    name: "STEFAN PASCU SCHOOL COURTYARD DESIGN GUIDE",
    imageUrl: require("../assets/files/05_scoala_stefan_pascu.jpg"),
    title: "STEFAN PASCU SCHOOL COURTYARD DESIGN GUIDE",
    description:
      "The guide covers various aspects for courtyard STEFAN PASCU School Courtyard design, from Apahida – Cluj-Napoca, including landscaping, seating arrangements, play areas, and educational zones. By incorporating elements that encourage creativity and collaboration, we aim to enhance the overall educational experience and foster a sense of community within the school. We invite educators, administrators, and community members to explore this guide and join us in our mission to create inspiring outdoor spaces that support the well-being and development of our students. Together, we can make our school courtyards a place where learning and play go hand in hand!",
    guideUrl: "https://triptic.ro/files/05_ARH_CJ_GHID%202022_WORLD%20BANK_APAHIDA.pdf",
  },
];

export default guides;
