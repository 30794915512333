import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import logoTriptic from "../assets/logoTriptic.png";
import logoTripticMobile from "../assets/logo-mobile.png"
import SubPagesCarousel from "./subPagesCarousel";

const pages = ["work", "office", "contact"];
const subPages = [
  "Profile",
  "Services",
  "Employment",
  "People",
  "Publications",
  "Awards",
  "Clients",
];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isFixed, setIsFixed] = useState(false);
  const subMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (subMenuRef.current) {
        const rect = subMenuRef.current.getBoundingClientRect();
        if (rect.top < 0 && !isFixed) {
          setIsFixed(true);
        } else if (rect.top >= 0 && isFixed) {
          setIsFixed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFixed]);

  const handleToggleNavMenu = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
    setIsNavOpen(true);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setIsNavOpen(false);
  };

  useEffect(() => {
    const body = document.body;
    body.style.overflow = isNavOpen ? "hidden" : "";
  }, [isNavOpen]);

  const isActiveLink = (page: any) => {
    return location.pathname === `/${page}`;
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#fffdf8",
          boxShadow: "none",
          border: "none",
          pt: 3,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            {/* Logo Box for desktop */}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Box
                  component="img"
                  src={logoTriptic}
                  alt="TRIPTIC Logo"
                  sx={{
                    flexGrow: 1,
                    maxWidth: "200px",
                    display: { xs: "none", md: "flex" },
                  }}
                />
              </Link>
            </Box>

            {/* Logo Box for mobile */}
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                alignItems: "center",
              }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <Box
                  component="img"
                  src={logoTriptic}
                  alt="TRIPTIC Logo"
                  sx={{
                    maxWidth: "130px",
                  }}
                />
              </Link>
            </Box>

            {/* Burger Menu for mobile */}
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ color: "black" }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "#656a53", display: "block" }}
                  component={Link}
                  to={`/${page}`}
                >
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "1.75rem", color: "#656a53" }} // Text color updated
                  >
                    {page}
                  </Typography>
                </Button>
              ))}
            </Box>

             

          
            
          </Toolbar>
          <Toolbar sx={{display: {  md: "none" },}}>
  {/* Subpages Menu for mobile */}
  {/* <Box
    sx={{
      width: "100%",
      backgroundColor: "#fffdf8",
      display: { xs: "flex", md: "none" },
      flexDirection: "column", // Added to display subpages one on top of the other
      justifyContent: "flex-start", // Changed to align subpages correctly
      alignItems: "flex-start",
      position: "fixed", // Added to freeze the menu
      top: "64px", // Adjust this value based on your header height
      zIndex: theme.zIndex.appBar,
    }}
  >
    {subPages.map((subPage) => (
      <Button
        key={subPage}
        component="a"
        href={`#${subPage}`}
        sx={{
          color: "#656a53",
          fontWeight: "bold",
          fontSize: "1rem",
          my: 1,
        }}
      >
        {subPage}
      </Button>
    ))}
  </Box> */}
  <SubPagesCarousel subPages={subPages} />
</Toolbar>
        </Container>
      </AppBar>

      {location.pathname === "/office" && (
        <Container
          maxWidth="xl"
          sx={{
            display: { xs: "flex", md: "block" },
          }}
        >
          <Toolbar disableGutters>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fffdf8",
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}
            >
              {subPages.map((subPage) => (
                <Button
                  key={subPage}
                  component="a"
                  href={`#${subPage}`}
                  sx={{
                    color: "#656a53", // Text color updated
                    fontWeight: "bold",
                    fontSize: "1.25rem",
                  }}
                >
                  {subPage}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      )}
      {isMobile && isNavOpen && (
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#fffdf8",
            zIndex: theme.zIndex.modal,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              p: 1,
              pt: 3,
            }}
          >
            <IconButton
              size="large"
              aria-label="close drawer"
              onClick={handleToggleNavMenu}
              color="inherit"
              sx={{ color: "black" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              mb: 2,
              flexDirection: "column",
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box
                component="img"
                src={logoTripticMobile}
                alt="TRIPTIC Logo"
                sx={{
                  maxWidth: "130px",
                  mb: 2,
                }}
              />
            </Link>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleToggleNavMenu}
                component={Link}
                to={`/${page}`}
                sx={{
                  my: 2,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  fontSize: "40px",
                  letterSpacing: "0px",
                  textTransform: "uppercase",
                  lineHeight: "100%",
                  color: "#656a53",
                  textDecoration: "none",
                  transition: "color .24s ease-out",
                  "&:hover": {
                    color: "black",
                  },
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Header;