interface Banner {
  title: string;
  image: string;
}

interface Banners {
  profile: Banner;
  services: Banner;
  employment: Banner;
  people: Banner;
  publications: Banner;
  awards: Banner;
  clients: Banner;
}

const bannersImages = {
  
  profile: require("../assets/banners/profile.jpg"),
  services: require("../assets/banners/services.jpg"),
  employment: require("../assets/banners/employment.jpg"),
  people: require("../assets/banners/team.jpg"),
  publications: require("../assets/banners/publications.jpg"),
  awards: require("../assets/banners/awards.jpg"),
  clients: require("../assets/banners/clients.jpg"),
}

const banners: Banners = {
  profile: {
    title: "Profile",
    image: bannersImages.profile,
  },
  services: {
    title: "Services",
    image: bannersImages.services
  },
  employment: {
    title: "Employment",
    image: bannersImages.employment,
  },
  people: {
    title: "People",
    image: bannersImages.people,
  },
  publications: {
    title: "Publications",
    image: bannersImages.publications,
     
  },
  awards: {
    title: "Awards",
    image: bannersImages.awards,
  },
  clients: {
    title: "Clients",
    image: bannersImages.clients,
  },
};

export default banners;
