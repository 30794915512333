import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { IconButton, Button } from "@mui/material";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface SubPagesCarouselProps {
  subPages: string[];
}

const SubPagesCarousel: React.FC<SubPagesCarouselProps> = ({ subPages }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [topPosition, setTopPosition] = React.useState(100); // Initial top position
  const maxSteps = subPages.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setTopPosition(0); // Change top position to 0 when scrolling
      } else {
        setTopPosition(100); // Reset top position when at the top of the page
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        position: "fixed", // Freeze the position
        top: `${topPosition}px`, // Dynamic top position
        zIndex: theme.zIndex.appBar,
        backgroundColor: "#fffdf8",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px 0",
        left: "50%", // Center horizontally
        transform: "translateX(-50%)", // Center horizontally
      }}
    >
      <IconButton
        onClick={handleBack}
        disabled={activeStep === 0}
        sx={{ color: "black" }}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        style={{ width: "100%" }} // Adjust the width as needed
      >
        {subPages.map((subPage, index) => (
          <div key={subPage}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Button
                component="a"
                href={`#${subPage}`}
                sx={{
                  color: "#656a53",
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                  display: "block",
                  width: "100%",
                  textAlign: "center",
            
                }}
              >
                {subPage}
              </Button>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <IconButton
        onClick={handleNext}
        disabled={activeStep === maxSteps - 1}
        sx={{ color: "black" }}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
};

export default SubPagesCarousel;