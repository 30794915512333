import { Grid } from "@mui/material";
import React from "react";
import ParagraphText from "../ParagraphText";

import ServicesCard from "../ServicesCard";

const Services = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}>
        {/* Left column (empty) */}
      </Grid>
      <Grid item xs={12} md={8}>
        <ParagraphText>
          With a multidisciplinary approach, our team seamlessly navigate
          through diverse project types, leveraging our knowledge to create
          architectural solutions. From large-scale urban planning initiatives
          or school yard designs, to agricultural, industrial projects or
          intricately detailed interior spaces, Triptic expertise spans across
          various domains, ensuring the delivery of outstanding results for
          every project we undertake.
        </ParagraphText>

        <ParagraphText>
          As a one-stop-shop company, Triptic offers comprehensive architectural
          services, providing end-to-end assistance to clients throughout the
          project lifecycle. Depending on the project’s complexity, we
          coordinate a team of licensed professionals with expertise in various
          domains to deliver specialized services.
        </ParagraphText>
        <ParagraphText>
          We leverage a diverse range of industry-leading software tools to
          bring visionary designs to life. With expertise in utilizing advanced
          software such as Revit, Rhino, Grasshopper, 3ds Max, Lumion,
          Photoshop, InDesign and V-Ray, we push the boundaries of architectural
          creation and deliver exceptional results.
        </ParagraphText>

        <ParagraphText>
          Our proficiency in Revit empowers us to create detailed and accurate
          building information models (BIM) that streamline the design and
          construction process. This software enables us to efficiently
          collaborate with project stakeholders, produce comprehensive
          documentation, and ensure precise coordination between different
          disciplines.
        </ParagraphText>

        <ServicesCard />
      </Grid>
      <Grid item xs={12} md={2}>
        {/* Right column (empty) */}
      </Grid>
    </Grid>
  );
};

export default Services;
