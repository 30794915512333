import { Grid, Typography } from "@mui/material";
import React from "react";
import ParagraphText from "../ParagraphText";
import TitleH4 from "../TitleH4";

const Awards = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}>
        {/* Left column (empty) */}
      </Grid>
      <Grid item xs={12} md={8}>
        <TitleH4>
          Best 100 Architecture Offices in IBC Focus Romania Award
        </TitleH4>
        <ParagraphText>
          Triptic has garnered recognition and acclaim within the Romanian
          architectural landscape. Notably, the studio was honored with a
          nomination in the prestigious “Best 100 Architecture Offices of 2022”
          by IBS Focus Romania. With a decade of experience, Triptic has left an
          indelible mark by successfully designing over 300,000 square meters of
          diverse and captivating buildings encompassing both private and public
          architecture.
        </ParagraphText>
        <ParagraphText>
          At Triptic we believe that our success is a direct result of our
          collaborative approach and our focus on understanding the unique needs
          of each project. We are grateful to our clients for their trust and
          support, which motivates us to push the boundaries of design and
          functionality.
        </ParagraphText>
        <ParagraphText>
          As we celebrate this achievement, we remain committed to our mission
          of creating innovative and sustainable architectural designs that
          enhance the built environment. We look forward to continuing our
          journey and contributing to the architectural landscape of Bucharest
          and beyond.
        </ParagraphText>

        <TitleH4>3rd place in Romanian Bussineses Top 2023</TitleH4>

        <ParagraphText>
          Triptic has achieved an impressive milestone by securing 3rd place in
          the prestigious Romanian Bussineses Top 2023 ranking for companies
          located in District 5, Bucharest. This recognition highlights our
          commitment to excellence and innovation in the field of architecture.
        </ParagraphText>

        <ParagraphText>
          The Romanian Bussineses Top 2023 ranking is conducted and certified by
          Listafirme.ro, a reputable platform that evaluates and ranks
          businesses across various sectors in Romania. This year’s ranking
          reflects the hard work, dedication, and creativity of our talented
          team, who continuously strive to deliver outstanding architectural
          solutions to our clients.
        </ParagraphText>
        <TitleH4>ISO 9001 Certification</TitleH4>

        <ParagraphText>
          Triptic has the ISO 9001 certification, a significant milestone that
          underscores our commitment to quality management and continuous
          improvement in our architectural services. This prestigious
          accreditation has been awarded by the International Management
          Accreditation Board and Management Certification, recognized
          authorities in the field of quality assurance.
        </ParagraphText>

        <ParagraphText>
          ISO 9001 is an internationally recognized standard that sets out the
          criteria for a quality management system (QMS). It is based on several
          quality management principles, including a strong customer focus, the
          involvement of top management, a process-based approach, and
          continuous improvement. Achieving this certification demonstrates our
          dedication to providing high-quality services that meet the needs and
          expectations of our clients.
        </ParagraphText>

        <ParagraphText>
          At Triptic we believe that quality is not just a goal but a continuous
          journey. This certification reflects our ongoing efforts to enhance
          our processes, improve client satisfaction, and deliver exceptional
          architectural solutions. Our team has worked diligently to implement
          the necessary systems and practices that align with ISO 9001
          standards, ensuring that we consistently meet and exceed industry
          benchmarks.
        </ParagraphText>

        <TitleH4>World Bank - Certified Urban Design</TitleH4>
        <ParagraphText>
          Triptic has expanded its team to include architects accredited by the
          World Bank in the field of urban regeneration. This significant
          addition enhances our capability to deliver innovative and sustainable
          solutions for urban development projects.
        </ParagraphText>
        <ParagraphText>
          We stand at the forefront of revolutionizing public spaces across
          Romania. Our team’s unwavering dedication and expertise have enabled
          us to coordinate so far a series of 10 influential guidelines for the
          development of public spaces in the country. These guidelines serve as
          a comprehensive roadmap, addressing key aspects such as accessibility,
          inclusivity, functionality, and aesthetics, to transform public spaces
          into vibrant, engaging, and socially cohesive environments. In the
          process, we engage closely with clients, stakeholders, and local
          communities, recognizing the intrinsic value of their input in shaping
          public spaces. By fostering open communication and integrating diverse
          perspectives, we create urban design solutions that resonate with the
          unique needs, aspirations, and identities of the communities they
          serve.
        </ParagraphText>
        <ParagraphText>
          Urban regeneration is a critical aspect of modern architecture,
          focusing on revitalizing and transforming urban areas to improve the
          quality of life for residents and promote economic growth. With the
          expertise of our World Bank-accredited architects, Triptic is
          well-equipped to tackle the complex challenges associated with urban
          renewal, ensuring that our projects are not only aesthetically
          pleasing but also socially and environmentally responsible.
        </ParagraphText>

        <ParagraphText>
          The World Bank accreditation signifies a high level of expertise and
          knowledge in urban regeneration practices. Our architects have
          undergone rigorous training and assessment, equipping them with the
          skills necessary to implement best practices in urban planning and
          development. This accreditation allows us to leverage global insights
          and methodologies, ensuring that our projects align with international
          standards and contribute positively to the communities we serve.
        </ParagraphText>
        <ParagraphText>
          As we embark on new urban regeneration initiatives, we are excited
          about the potential to create vibrant, sustainable spaces that meet
          the needs of today while anticipating the challenges of tomorrow. Our
          team is dedicated to pushing the boundaries of design and innovation,
          and we look forward to making a meaningful impact in the urban
          landscapes we work within.
        </ParagraphText>
        <TitleH4>nZEB Accreditation by INCD URBAN-INCERC</TitleH4>

        <ParagraphText>
          In the ever-evolving field of architecture, sustainability and energy
          efficiency have become paramount. Triptic, a forward-thinking
          architecture firm, is proud to announce that it boasts a team of
          architects accredited by INCD URBAN-INCERC and Building Knowledge Hub
          Romania. This accreditation highlights Triptic's commitment to
          designing buildings that meet the stringent standards of nearly Zero
          Energy Buildings (nZEB).
        </ParagraphText>

        <ParagraphText>
          nZEBs are structures that have a very high energy performance, where
          the energy consumed is nearly equal to the energy produced on-site
          from renewable sources. This innovative approach not only reduces the
          environmental impact of buildings but also promotes a sustainable
          future. The architects at Triptic are equipped with the knowledge and
          skills necessary to navigate the complexities of nZEB design, ensuring
          that each project is not only aesthetically pleasing but also
          environmentally responsible.
        </ParagraphText>

        <ParagraphText>
          The accreditation from INCD URBAN-INCERC and Building Knowledge Hub
          Romania signifies that Triptic's architects are recognized experts in
          the field. They are trained to implement advanced design strategies
          and technologies that optimize energy efficiency, enhance indoor
          comfort, and minimize carbon footprints. This expertise allows Triptic
          to deliver projects that align with both national and European energy
          performance regulations.
        </ParagraphText>

        <ParagraphText>
          At Triptic, the team believes that architecture should not only serve
          its functional purpose but also contribute positively to the
          environment. By prioritizing nZEB principles, they are paving the way
          for a new standard in building design that harmonizes with nature
          while meeting the needs of modern society.
        </ParagraphText>
      </Grid>
      <Grid item xs={12} md={2}>
        {/* Right column (empty) */}
      </Grid>
    </Grid>
  );
};

export default Awards;
